.App {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
a,a:hover{
  text-decoration: none;
}
button {
  cursor: pointer;
}
.social-btn {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  position: relative;
  z-index: 10;
}
.social-btn a {
  cursor: pointer;
  color: white;
}
.text-green {
  color: #009e66;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* common css */
.text-center {
  text-align: center;
}
.btn-green {
  font-size: 1rem;
  font-weight: 600;
  margin: 5px 0;
  width: 100%;
  color: white;
  background-color: #009e66;
  padding: 10px;
  border: 1px solid #009e66;
  transition: all 0.3s ease-in-out;
}
.btn-white {
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  padding: 10px;
  border: 1px solid #009e66;
  transition: all 0.3s ease-in-out;
  color: #009e66;
  background-color: white;
  transition: all 0.3s ease-in-out;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
